import {createEffect, ofType, Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {ROUTER_NAVIGATION, RouterNavigationAction} from '@ngrx/router-store';
import {of, iif} from 'rxjs';
import {switchMap, map, catchError, mergeMap, concatMap, filter, withLatestFrom} from 'rxjs/operators';
import {Params} from '@angular/router';

import * as actions from './actions';
import {FocusGroupsService} from '@services/focus-groups.service';
import {FocusGroup, FocusGroupMessageTypes} from '@models/focus-groups';
import {MergedRoute} from '../../route-serializer';
import {Store} from '@ngrx/store';
import {RootStoreState} from '@store/index';
import {connectionSuccessfulMessage} from '@store/constants';
import {selectFocusGroupState} from '@store/focus-group-store/selectors';
import {classToPlain} from 'class-transformer';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {parse} from 'json5';
import {ThunderWebSocket} from "@models/thunder-web-socket";


@Injectable()
export class Effects {

  getFocusGroups$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetFocusGroups>(actions.ActionTypes.LIST),
    map(action => action.payload),
    switchMap(payload => {
      return this.focusGroupsService.getFocusGroups(payload.isHistorical, payload.isTalentPipeline, true).pipe(
        switchMap((focusGroups: FocusGroup[]) => {
          return of({
            type: actions.ActionTypes.LIST_SUCCESS,
            payload: focusGroups
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.LIST_FAILED,
            payload: error
          });
        })
      );
    })
  ));

  getFocusGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.GetFocusGroup>(actions.ActionTypes.GET),
    map(action => action.payload),
    switchMap(payload => {
      const {id, versionDate} = payload;
      return this.focusGroupsService.getFocusGroup(id, versionDate).pipe(
        switchMap((focusGroup: FocusGroup) => {
          return of({
            type: actions.ActionTypes.GET_SUCCESS,
            payload: focusGroup
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.GET_FAILED,
            payload: error
          });
        })
      );
    }),
  ));

  saveFocusGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveFocusGroup>(actions.ActionTypes.SAVE),
    map(action => action.payload),
    concatMap(payload => {
      return this.focusGroupsService.saveFocusGroup(payload).pipe(
        mergeMap(focusGroup => {
          return of({
            type: actions.ActionTypes.SAVE_SUCCESS,
            payload: focusGroup
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.SAVE_FAILED,
            payload: error
          });
        })
      );
    })
  ));

  deleteFocusGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.DeleteFocusGroup>(actions.ActionTypes.DELETE),
    map(action => action.payload),
    mergeMap(payload => {
      return this.focusGroupsService.deleteFocusGroup(payload).pipe(
        mergeMap(() => {
          return of({
            type: actions.ActionTypes.DELETE_SUCCESS
          });
        }),
        catchError(error => {
          return of({
            type: actions.ActionTypes.DELETE_FAILED,
            payload: error
          });
        })
      );
    }),
  ));

  updateChannelName$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateChannelName>(actions.ActionTypes.UPDATE_CHANNEL_NAME),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.UPDATE_CHANNEL_NAME_SUCCESS,
        payload: payload
      });
    }),
  ));

  requestInitialFocusGroup = createEffect(() => this.actions$.pipe(
    ofType<actions.RequestInitialMyEvaluation>(actions.ActionTypes.REQUEST_INITIAL_FOCUS_GROUP),
    map(action => action.payload),
    mergeMap(payload => {
      this.focusGroupsService.sendFocusGroupSocketAction(connectionSuccessfulMessage);
      return of({
        type: actions.ActionTypes.REQUEST_INITIAL_FOCUS_GROUP_SUCCESS,
        payload: payload
      });
    }),
  ));

  updateActiveUsers$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateActiveUsers>(actions.ActionTypes.UPDATE_ACTIVE_USERS),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.UPDATE_ACTIVE_USERS_SUCCESS,
        payload: payload
      });
    }),
  ));

  saveFocusGroupAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveAction>(actions.ActionTypes.SAVE_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      this.focusGroupsService.sendFocusGroupSocketAction(payload);
      return of({
        type: actions.ActionTypes.SAVE_ACTION_SUCCESS,
        payload: payload
      });
    }),
  ));

  receiveFocusGroupAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.ReceiveAction>(actions.ActionTypes.RECEIVE_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      if (payload.data.errors) {
        return of({
          type: actions.ActionTypes.RECEIVE_ACTION_FAILED,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.RECEIVE_ACTION_SUCCESS,
          payload: payload
        });
      }
    }),
  ));

  receiveEntityContext$ = createEffect(() => this.actions$.pipe(
    ofType<actions.ReceiveEntityContext>(actions.ActionTypes.RECEIVE_ENTITY_CONTEXT),
    map(action => action.payload),
    mergeMap(payload => {
      if (payload.data.errors) {
        return of({
          type: actions.ActionTypes.RECEIVE_ENTITY_CONTEXT_FAILED,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.RECEIVE_ENTITY_CONTEXT_SUCCESS,
          payload: payload
        });
      }
    }),
  ));

  refreshConnection$ = createEffect(() => this.actions$.pipe(
    ofType<actions.RefreshConnection>(actions.ActionTypes.REFRESH_CONNECTION),
    map(action => action.payload),
    switchMap((payload) => {
      this.focusGroupsService.closeFocusGroupSocket(null);
      if (this.connectFocusGroupSocket(payload.focusGroupID)) {
        return of({
          type: actions.ActionTypes.REFRESH_CONNECTION_SUCCESS,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.REFRESH_CONNECTION_FAILED,
          payload: payload
        });
      }
    })
  ));

  syncFocusGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SyncFocusGroup>(actions.ActionTypes.SYNC),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.SYNC_SUCCESS,
        payload: payload
      });
    }),
  ));

  updateFocusGroup$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateFocusGroup>(actions.ActionTypes.UPDATE),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.UPDATE_SUCCESS,
        payload: payload
      });
    }),
  ));

  openFocusGroupSocket$ = createEffect(() => this.actions$.pipe(
    ofType<actions.OpenFocusGroupSocket>(actions.ActionTypes.OPEN_FOCUS_GROUP_SOCKET),
    map(action => action.payload),
    switchMap((payload) => {
      this.focusGroupsService.closeFocusGroupSocket(null);
      if (this.connectFocusGroupSocket(payload.focusGroupID)) {
        return of({
          type: actions.ActionTypes.OPEN_FOCUS_GROUP_SOCKET_SUCCESS,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.OPEN_FOCUS_GROUP_SOCKET_FAILED,
          payload: payload
        });
      }
    })
  ));

  openMyEvaluationSocket$ = createEffect(() => this.actions$.pipe(
    ofType<actions.OpenMyEvaluationSocket>(actions.ActionTypes.OPEN_MY_EVALUATION_SOCKET),
    map(action => action.payload),
    switchMap((payload) => {
      if (this.connectMyEvaluationSocket(payload.focusGroupID)) {
        return of({
          type: actions.ActionTypes.OPEN_MY_EVALUATION_SOCKET_SUCCESS,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.OPEN_MY_EVALUATION_SOCKET_FAILED,
          payload: payload
        });
      }
    })
  ));

  updateMyEvaluationChannelName$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateMyEvaluationChannelName>(actions.ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME),
    map(action => action.payload),
    mergeMap(payload => {
      return of({
        type: actions.ActionTypes.UPDATE_MY_EVALUATION_CHANNEL_NAME_SUCCESS,
        payload: payload
      });
    }),
  ));

  requestInitialMyEvaluation$ = createEffect(() => this.actions$.pipe(
    ofType<actions.RequestInitialMyEvaluation>(actions.ActionTypes.REQUEST_INITIAL_MY_EVALUATION),
    map(action => action.payload),
    mergeMap(payload => {
      this.focusGroupsService.sendMyEvaluationSocketAction(connectionSuccessfulMessage);
      return of({
        type: actions.ActionTypes.REQUEST_INITIAL_MY_EVALUATION_SUCCESS,
        payload: payload
      });
    }),
  ));

  updateMyEvaluation$ = createEffect(() => this.actions$.pipe(
    ofType<actions.UpdateMyEvaluation>(actions.ActionTypes.UPDATE_MY_EVALUATION),
    map(action => action.payload),
    mergeMap(payload => {
      console.log(payload);
      return of({
        type: actions.ActionTypes.UPDATE_MY_EVALUATION_SUCCESS,
        payload: payload
      });
    }),
  ));

  saveMyEvaluationAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.SaveMyEvaluationAction>(actions.ActionTypes.SAVE_MY_EVALUATION_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      this.focusGroupsService.sendMyEvaluationSocketAction(payload);
      return of({
        type: actions.ActionTypes.SAVE_MY_EVALUATION_ACTION_SUCCESS,
        payload: payload
      });
    }),
  ));

  receiveMyEvaluationAction$ = createEffect(() => this.actions$.pipe(
    ofType<actions.ReceiveMyEvaluationAction>(actions.ActionTypes.RECEIVE_MY_EVALUATION_ACTION),
    map(action => action.payload),
    mergeMap(payload => {
      if (payload.data.errors) {
        return of({
          type: actions.ActionTypes.RECEIVE_MY_EVALUATION_ACTION_FAILED,
          payload: payload
        });
      }
      else {
        return of({
          type: actions.ActionTypes.RECEIVE_MY_EVALUATION_ACTION_SUCCESS,
          payload: payload
        });
      }
    }),
  ));

  // Router Effects
  focusGroupsRouted$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => action.payload.routerState.path === '/bild/focus-groups'),
    concatMap((action: RouterNavigationAction<MergedRoute>) => {
      let params: Params;
      params = action.payload.routerState.queryParams;
      params.isTalentPipeline = false;
      return of(new actions.GetFocusGroups(params));
    })
  ));

  focusGroupsTalentRouted$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => action.payload.routerState.path === '/bild/talent-pipeline'),
    concatMap((action: RouterNavigationAction<MergedRoute>) => {
      let params: Params;
      params = action.payload.routerState.queryParams;
      params.isTalentPipeline = true;
      return of(new actions.GetFocusGroups(params));
    })
  ));

  focusGroupRelatedRouted$ = createEffect((): any => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => action.payload.routerState.path.startsWith('/bild/focus-groups/:id') || action.payload.routerState.path.startsWith('/bild/talent-pipeline/:id')),
    concatMap((action: RouterNavigationAction<MergedRoute>) => {
      this.connectFocusGroupSocket(+action.payload.routerState.params.id);
      return of();
    })
  ));

  nonFocusGroupRelatedRouted$ = createEffect((): any => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => {
      return !action.payload.routerState.path.startsWith('/bild/focus-groups/:id') && !action.payload.routerState.path.startsWith('/draft/flow-chart') && !action.payload.routerState.path.startsWith('/bild/talent-pipeline/:id');
    }),
    switchMap(() => {
      this.focusGroupsService.closeFocusGroupSocket(false);
      this.store$.dispatch(new actions.UpdateChannelName(null));
      return of();
    })
  ));

  entityFocusGroupsRouted$ = createEffect(() => this.actions$.pipe(
    ofType(ROUTER_NAVIGATION),
    filter((action: RouterNavigationAction<MergedRoute>) => action.payload.routerState.path === '/bild/entities/people/:id/focus-groups'),
    concatMap((action: RouterNavigationAction<MergedRoute>) => {
      return of(new actions.GetEntityFocusGroups({id: action.payload.routerState.params.id, ...action.payload.routerState.queryParams}));
    })
  ));

  constructor(
    protected focusGroupsService: FocusGroupsService,
    protected actions$: Actions,
    protected broadcastChannelService: BroadcastChannelService,
    protected store$: Store<RootStoreState.State>,
  ) {}

  connectFocusGroupSocket(focusGroupID): any {
    let focusGroupSocket = this.focusGroupsService.createFocusGroupSocket(focusGroupID);

    focusGroupSocket.messages.subscribe((event) => {
      if (event.data) {
        const actionData = parse(event.data);
        switch (actionData.type) {
          case FocusGroupMessageTypes.WEBSOCKET_CHANNEL_NAME: {
            this.store$.dispatch(new actions.UpdateChannelName(actionData.data));
            this.store$.dispatch(new actions.RequestInitialFocusGroup(actionData.data));
            break;
          }
          case FocusGroupMessageTypes.FOCUS_GROUP_STATE: {
            this.store$.dispatch(new actions.UpdateFocusGroup(actionData.data));
            break;
          }
          case FocusGroupMessageTypes.ACTIVE_USERS_CHANGE: {
            this.store$.dispatch(new actions.UpdateActiveUsers(actionData.data));
            break;
          }
          case FocusGroupMessageTypes.FOCUS_GROUP_ACTION: {
            this.store$.dispatch(new actions.ReceiveAction(actionData));
            break;
          }
          case FocusGroupMessageTypes.ENTITY_CONTEXT: {
            this.store$.dispatch(new actions.ReceiveEntityContext(actionData));
            break;
          }
        }
      }

      return focusGroupSocket;
    });

    focusGroupSocket.onclose = (event) => {
      this.store$.dispatch(new actions.UpdateChannelName(null));
      console.log('Socket is closed. Reconnect will be attempted in 5 second.');
      let $this = this;
      setTimeout(function() {
        $this.connectFocusGroupSocket(focusGroupID);
      }, 5000);
    }

    focusGroupSocket.onerror = (event) => {
      console.log('Socket encountered error');
      focusGroupSocket.close();
    }
  }

  connectMyEvaluationSocket(focusGroupID): any {
    const myEvaluationSocket = this.focusGroupsService.createMyEvaluationSocket(focusGroupID);

    myEvaluationSocket.messages.subscribe((event) => {
      if (event.data) {
        const actionData = parse(event.data);
        switch (actionData.type) {
          case FocusGroupMessageTypes.WEBSOCKET_CHANNEL_NAME: {
            this.store$.dispatch(new actions.UpdateMyEvaluationChannelName(actionData.data));
            this.store$.dispatch(new actions.RequestInitialMyEvaluation(actionData.data));
            break;
          }
          case FocusGroupMessageTypes.MY_EVALUATION_ACTION: {
            this.store$.dispatch(new actions.ReceiveMyEvaluationAction(actionData));
            break;
          }
        }
      }

      return myEvaluationSocket;
    });

    myEvaluationSocket.onclose = (event) => {
      this.store$.dispatch(new actions.UpdateMyEvaluationChannelName(null));
      console.log('Socket is closed. Reconnect will be attempted in 5 second.');
      let $this = this;
      setTimeout(function() {
        $this.connectMyEvaluationSocket(focusGroupID);
      }, 5000);
    }

    myEvaluationSocket.onerror = (event) => {
      console.log('Socket encountered error');
      myEvaluationSocket.close();
    }
  }
}
