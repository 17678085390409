import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {classToPlain, plainToClass} from 'class-transformer';

import {BaseService, CustomHttpParamEncoder} from './base.service';
import {EntitiesService} from './entities.service';
import {FocusGroup, FocusGroupErrorKeyMap} from '@models/focus-groups';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusGroupGrouping} from '@models/focus-group-groupings';
import {Entity} from '@models/entities';
import {map} from 'rxjs/operators';
import {BroadcastChannelService} from '@services/broadcast-channel.service';
import {createThunderWebSocket, ThunderWebSocket} from '@models/thunder-web-socket';

@Injectable({
  providedIn: 'root',
})
export class FocusGroupsService extends BaseService {

  memberDataSubject: BehaviorSubject<Entity[]> = new BehaviorSubject<Entity[]>([]);
  memberData: Observable<Entity[]>;
  focusGroupSocket: ThunderWebSocket;
  myEvaluationSocket: ThunderWebSocket;

  constructor(protected http: HttpClient, protected broadcastChannelService: BroadcastChannelService, protected entitiesService: EntitiesService) {
    super(http, broadcastChannelService);
    this.errorKeyMap = FocusGroupErrorKeyMap;
    this.memberData = this.memberDataSubject.asObservable();
  }

  getFocusGroups(isHistorical?: boolean, isTalentPipeline?: boolean, isSimple?: boolean,
                 primaryFunctionIDs: number[] = [], priorities: number[] = [],
                 isProVals: boolean[] = [], minDate: string = '', maxDate: string = ''): Observable<any> {
    const endpoint = `${this.baseUrl}/focusGroups`;
    let params: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (coerceBooleanProperty(isHistorical)) {
      params = params.set('isHistorical', String(isHistorical));
    }
    if (coerceBooleanProperty(isTalentPipeline)) {
      params = params.set('isTalentPipeline', String(isTalentPipeline));
    }
    if (coerceBooleanProperty(isSimple)) {
      params = params.set('isSimple', String(isSimple));
    }

    if (primaryFunctionIDs.length > 0) {
      params = params.set('primaryFunctionIDs', primaryFunctionIDs.join(','));
    }

    if (priorities.length > 0) {
      params = params.set('priorities', priorities.join(','));
    }

    if (isProVals.length > 0) {
      params = params.set('isProVals', isProVals.join(','));
    }

    if (minDate.length > 0) {
      params = params.set('minDate', minDate);
    }

    if (maxDate.length > 0) {
      params = params.set('maxDate', maxDate);
    }

    return this.get(endpoint, params);
  }

  getEntityFocusGroups(id: number, isHistorical?: boolean): Observable<any> {
    const endpoint = `${this.baseUrl}/entities/${id}/focusGroups`;

    let params: HttpParams = new HttpParams();
    if (coerceBooleanProperty(isHistorical)) {
      params = params.set('isHistorical', String(isHistorical));
    }

    return this.get(endpoint, params);
  }

  getFocusGroup(id: number, versionDate?: string): Observable<any> {
    const endpoint = `${this.baseUrl}/focusGroups/${id}`;

    let params: HttpParams = new HttpParams();

    if (versionDate && versionDate !== 'Today') {
      params = params.set('asOfDate', versionDate);
    }

    return this.get(endpoint, params);
  }

  getMembers(id: number, query?: string): Observable<Entity[]> {
    let params: HttpParams = new HttpParams();

    if (query) {
      params = params.set('entityType', query);
    }

    const endpoint = `${this.baseUrl}/focusGroups/${id}/members`;

    return this.get(endpoint, params).pipe(map(
      data => {
        data = plainToClass(Entity, data);
        this.memberDataSubject.next(data);
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  saveFocusGroup(focusGroup: FocusGroup): Observable<FocusGroup> {
    let endpoint: string;
    let method: any;

    if (focusGroup.id) {
      endpoint = `${this.baseUrl}/focusGroups/${focusGroup.id}`;
      method = this.put.bind(this);
    } else {
      endpoint = `${this.baseUrl}/focusGroups`;
      method = this.post.bind(this);
    }

    const payload = classToPlain(focusGroup);
    payload.groupings = null; // Big groups are too big to send as payload ... remove groupings to mitigate
    return method(endpoint, payload);
  }

  deleteFocusGroup(focusGroup: FocusGroup): Observable<any> {
    const endpoint = `${this.baseUrl}/focusGroups/${focusGroup.id}`;

    return this.delete(endpoint);
  }

  getUserPresets(user, presetCategories) {
    const endpoint = `${this.baseUrl}/users/${user.id}/presets`;
    let params: HttpParams = new HttpParams();
    if (presetCategories) {
      params = params.set('categoryIDs', presetCategories);
    }

    return this.get(endpoint, params).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  getMyEvaluation(focusGroupID: number) {
    const endpoint = `${this.baseUrl}/focusGroups/${focusGroupID}/myEvaluation`;

    return this.get(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  createUserPreset(user, presetData) {
    const endpoint = `${this.baseUrl}/users/${user.id}/presets`;

    return this.post(endpoint, presetData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  updateUserPreset(user, presetData) {
    const endpoint = `${this.baseUrl}/users/${user.id}/preset/${presetData.id}`;

    return this.put(endpoint, presetData).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }

  deleteUserPreset(user, presetData) {
    const endpoint = `${this.baseUrl}/users/${user.id}/preset/${presetData.id}`;

    return this.delete(endpoint).pipe(map(
      data => {
        return data;
      },
      error => {
        return error;
      }
    ));
  }


  getFocusGroupMeasuresExport(entityIDs: number[] = []): Observable<any> {
    const endpoint = `${this.baseUrl}/focusGroups/exportMeasures`;
    let params: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    if (entityIDs.length > 0) {
      params = params.set('entityIDs', entityIDs.join(','));
    }

    return this.get(endpoint, params);
  }

  createFocusGroupSocket(focusGroupID: number) {
    this.focusGroupSocket = createThunderWebSocket(this.focusGroupSocket, `focusGroups/${focusGroupID}`, this.broadcastChannelService);
    return this.focusGroupSocket;
  }

  closeFocusGroupSocket(attemptReconnect) {
    if (!attemptReconnect && this.focusGroupSocket) {
      this.focusGroupSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      }
    }
    if (this.focusGroupSocket) {
      this.focusGroupSocket.close();
    }
  }

  sendFocusGroupSocketAction(actionData) {
    this.focusGroupSocket.send(JSON.stringify(actionData));
  }

  createMyEvaluationSocket(focusGroupID: number): ThunderWebSocket {
    this.myEvaluationSocket = createThunderWebSocket(this.myEvaluationSocket, `myEvaluation/${focusGroupID}`, this.broadcastChannelService);
    return this.myEvaluationSocket;
  }

  closeMyEvaluationSocket(attemptReconnect) {
    if (!attemptReconnect && this.myEvaluationSocket) {
      this.myEvaluationSocket.onclose = (event) => {
        console.log('Socket should be closed for good');
      }
    }
    if (this.myEvaluationSocket) {
      this.myEvaluationSocket.close();
    }
  }

  sendMyEvaluationSocketAction(actionData) {
    this.myEvaluationSocket.send(JSON.stringify(actionData));
  }
}
